import React, { useRef, useLayoutEffect, useState } from "react";
import { graphql } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { Container } from "react-bootstrap";
import { gsap } from "gsap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import { isBrowser } from "../utilities/checkEnvironment";

const Skills = ({ data }) => {
  const [refreshAnimation, setRefreshAnimation] = useState(false);

  if (!data) return null;
  const { skills } = data.page.data;
  const skillsPageRef = useRef();

  useLayoutEffect(() => {
    const skillsCtx = gsap.context(() => {
      // Car comes from the left of the screen
      gsap.from(".car", {
        delay: 0.5,
        x: "-=150%",
        duration: 2,
        ease: "power2.out",
      });

      // Every one second a skill icon shows and goes to the car
      if (isBrowser) {
        skills.map((skill, i) => {
          return gsap.from(`.skill-icon-${i}`, {
            delay: 0.7 + 1 * i,
            x: `${window.innerWidth}`,
            y: -30,
            ease: "none",
            duration: `${2 + window.innerWidth / 600}`,
            scale: 2,
          });
        });
        // opacity 0 to skill icons
        gsap.to(".skill-icon", {
          delay: 0.7 + 1 * skills.length + (2 + window.innerWidth / 600),
          opacity: 0,
          duration: 0,
          // ease: "power2.in",
        });
        // go car to the end of screen
        gsap.to(".car", {
          delay: 1 * skills.length + (2 + window.innerWidth / 600),
          x: `${window.innerWidth}`,
          duration: 3,
          ease: "power2.in",
        });
      }
    }, skillsPageRef);

    // Refresh rate for the animation
    const refreshRate =
      (0.7 + 1 * skills.length + (2 + window.innerWidth / 600) + 3.5) * 1000;
    // Set state from animation refresh
    setTimeout(() => {
      setRefreshAnimation(!refreshAnimation);
    }, refreshRate);
    return () => {
      skillsCtx.revert();
    }; // animation cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAnimation]);

  const skillIcons = skills.map((skill, i) => {
    return (
      <div
        key={skill.id}
        className={`skill-icon skill-icon-${i}`}
        style={{
          backgroundColor: skill.background_color,
        }}
      >
        {skill.skill_icon.gatsbyImageData && (
          <GatsbyImage image={skill.skill_icon.gatsbyImageData} alt="image" />
        )}
      </div>
    );
  });

  const skillsListJSX = skills.map((skill) => {
    return (
      <div
        key={skill.id}
        className="skill-list-item d-flex justify-content-center align-items-center"
      >
        {skill.skill_icon.gatsbyImageData && (
          <div
            style={{
              backgroundColor: skill.background_color,
            }}
            className="skill-list-item-icon me-2"
          >
            <GatsbyImage image={skill.skill_icon.gatsbyImageData} alt="image" />
          </div>
        )}
        <h5 className="m-0">{skill.skill_name}</h5>
      </div>
    );
  });

  return (
    <>
      <Seo page={data.page} />
      <Layout>
        <div className="skills-page pb-5" ref={skillsPageRef}>
          <h3 className="skills-title pt-4 pt-sm-5 px-5 w-75">
            {data.page.data.title}
          </h3>

          <div className="animation-hero mb-5">
            <div className="hightway-wrapper">
              <div className="highway" />
            </div>
            <div className="city" />
            {skillIcons}
            <div className="car">
              <StaticImage
                src="../images/skills/Car_Animation_Img/car-white-mercendes.png"
                alt="car"
                placeholder="none"
              />
              <div className="wheel">
                <StaticImage
                  src="../images/skills/Car_Animation_Img/wheel.png"
                  alt="wheel"
                  className="back-wheel"
                />
                <StaticImage
                  src="../images/skills/Car_Animation_Img/wheel.png"
                  alt="wheel"
                  className="front-wheel"
                />
              </div>
            </div>
          </div>
          <Container className="skill-list-title px-3 px-sm-4 mb-5">
            <PrismicRichText field={data.page.data.skill_list_title.richText} />
          </Container>
          <Container className="skill-list-container px-3 px-sm-4 d-flex flex-wrap gap-5">
            {skillsListJSX}
          </Container>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query skillsQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicSkills(lang: { eq: $lang }) {
      alternate_languages {
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        skill_list_title {
          richText
        }
        skills {
          id
          skill_name
          skill_icon {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          background_color
        }
      }
    }
  }
`;

export default Skills;
